﻿////import { Chart } from "chart.js";

import { Chart, registerables } from 'chart.js'
Chart.register(...registerables);

async function fetchWithTimeout(resource, options = {}) {
    const { timeout = 3000 } = <any>options;

    const controller = new AbortController();
    const id = setTimeout(() => controller.abort(), timeout);
    const response = await fetch(resource, {
        ...options,
        signal: controller.signal
    });
    clearTimeout(id);
    return response;
}

const delay = ms => new Promise(res => setTimeout(res, ms));

async function TimedHttpRequest() {
    var start = Date.now();
    end = Date.now();
    try {
        var results = await fetchWithTimeout('/ping');
        end = Date.now();
    } catch (ex) {
        var end = start + 3000;
    }
    return (end-start)/1000;
}

var idx = 0;

window.onload = async () => {

    var chart = new Chart(<any>document.getElementById("line-chart"), {
            type: 'line',
            data:
            {
                labels:[],
                datasets: [{
                    label:'pings',
                    data: [],
                    fill: false,
                    borderColor: 'rgb(75, 192, 192)',
                    tension: 0.1
                }]
            },
            options: {
                events: [],
                plugins: { legend: { display: false } }
            }
        });

    document.getElementById("clear-recording").onclick = () => {
        idx = 0;
        chart.data.datasets[0].data = [];
        chart.data.labels = [];
        chart.update();
    };

    var isPaused = false;
    document.getElementById("pause-recording").onclick = () => {
        isPaused = !isPaused;
        if (isPaused) {
            document.getElementById("pause-recording").innerText = "start recording";
        } else {
            document.getElementById("pause-recording").innerText = "pause recording";
        }
    };

    while (true) {
        if (isPaused) {
            await delay(50);
            continue;
        }

        var start = Date.now();
        var ping = await TimedHttpRequest();
        var end = Date.now();

        if (isPaused) {
            continue;
        }

        //allPings.push(ping);
        chart.data.datasets[0].data.push(ping);
        chart.data.labels.push(idx++);
        chart.update();

        await delay(Math.max(0, 1000 - (end - start)));
        //await delay(Math.max(0, 3000 - (end - start)));

    }
        //.then(response => response.json())
        //.then(data => console.log(data));

}

